import { apolloHandleInitialError } from '@stuller/stullercom/data-access/apollo-client'
import {
  GetActiveCategoryBySanitizedPathDocument,
  type GetActiveCategoryBySanitizedPathQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { GetCategoryPage2Document, GetCategoryPage3Document, GetCategoryPageDocument, type GetCategoryPageQuery } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type CategoryPageType } from './types'

const getInitialProps: CategoryPageType['getInitialProps'] = async (context) => {
  const { apolloClient } = context
  const sanitizedPath = new URL(`https://${context.auth.host}${context.asPath}`)
    .pathname
    .toLowerCase()
    .replace(/\/$/, '')
    .replace(/\/next\b/, '')
    .replace(/\/browse\b\//, '')
  let categoryData: GetActiveCategoryBySanitizedPathQuery['activeCategoryBySanitizedPath'] | null = null

  try {
    const { data } = await apolloClient.query<GetActiveCategoryBySanitizedPathQuery>({
      query: GetActiveCategoryBySanitizedPathDocument,
      variables: { sanitizedPath }
    })

    categoryData = data.activeCategoryBySanitizedPath

    // 404 if category is not found or is not a content only category
    if (categoryData == null ||
      categoryData.displayTemplate !== 'ContentOnly'
    ) {
      return { statusCode: 404 }
    }

    // If there is a override url, redirect to it
    if (categoryData.overrideUrl != null) {
      return { statusCode: 302, location: categoryData.overrideUrl }
    }

    // Make all queries in parallel
    const results = await Promise.all([
      apolloClient.query<GetCategoryPageQuery>({
        query: GetCategoryPageDocument,
        variables: { categoryId: categoryData.id },
        context: {
          contentstackLink: true
        }
      }),
      apolloClient.query<GetCategoryPageQuery>({
        query: GetCategoryPage2Document,
        variables: { categoryId: categoryData.id },
        context: {
          contentstackLink: true
        }
      }),
      apolloClient.query<GetCategoryPageQuery>({
        query: GetCategoryPage3Document,
        variables: { categoryId: categoryData.id },
        context: {
          contentstackLink: true
        }
      })
    ])
    const cmsData: typeof results[0]['data'] = JSON.parse(JSON.stringify(results[0]?.data))

    // 404 if category not found in CMS
    if (cmsData?.all_category_page?.items?.[0] == null) {
      return { statusCode: 404 }
    }

    // Combine the results
    const pageContents2 = results[1]?.data.all_category_page?.items?.[0]?.page_contents ?? []
    const pageContents3 = results[2]?.data.all_category_page?.items?.[0]?.page_contents ?? []
    cmsData.all_category_page.items[0].page_contents = (cmsData.all_category_page.items[0].page_contents ?? []).map((content, index) => {
      if (content?.__typename == null && pageContents2[index]?.__typename != null) {
        return pageContents2[index]
      }
      if (content?.__typename == null && pageContents3[index]?.__typename != null) {
        return pageContents3[index]
      }

      return content
    })

    // Fix up the cache cause we are combining multiple queries
    apolloClient.writeQuery({
      query: GetCategoryPageDocument,
      variables: { categoryId: categoryData.id },
      data: cmsData
    })
    apolloClient.writeQuery({
      query: GetCategoryPage2Document,
      variables: { categoryId: categoryData.id },
      data: cmsData
    })
    apolloClient.writeQuery({
      query: GetCategoryPage3Document,
      variables: { categoryId: categoryData.id },
      data: cmsData
    })

    return { categoryCmsData: cmsData, categoryData }
  } catch (error) {
    return await apolloHandleInitialError(error, context)
  }
}

export {
  getInitialProps
}
