import {
  ContentstackBlogCardGroup,
  ContentstackCardGroup,
  ContentstackCardWithBackgroundGroup,
  ContentstackEscapeHatch,
  ContentstackLargeShoppingBuckets,
  ContentstackLegacyCmsContent,
  ContentstackMediaCalloutGroup,
  ContentstackPageHeader,
  ContentstackProductCardCarousel,
  ContentstackRow,
  ContentstackSearchBySerialNumber,
  ContentstackSmallShoppingBuckets,
  ContentstackStoneShapeIcons,
  ContentstackTopic,
  ContentstackVerticalMediaBoxGroup,
  ContentstackVideoCarousel
} from '@stuller/stullercom/feat/contentstack-shared'
import {
  useCategoryRecommendations
} from '@stuller/stullercom/feat/recommendations'
import { MetaDescription, Title } from '@stuller/stullercom/feat/layout'
import { getInitialProps } from './getInitialProps'
import { type CategoryPageType } from './types'
import { isStringEmpty } from '@stuller/shared/util/core'

/**
 * The Page that will render the content entries with the Category Page content type
 */
const CategoryPage: CategoryPageType = ({ categoryData, categoryCmsData }) => {
  useCategoryRecommendations([{ placementId: 'category_page' }], categoryData.id)
  const page = categoryCmsData?.all_category_page?.items?.[0]
  const pageContents = page?.page_contents
  const pageHeaders = page?.page_headerConnection?.edges

  return (
    <>
      {!isStringEmpty(categoryData.pageTitle) && <Title>{categoryData.pageTitle}</Title>}
      {categoryData.pageMetaDescription != null && <MetaDescription>{categoryData.pageMetaDescription}</MetaDescription>}

      {pageHeaders?.map((header, key) => (
        <ContentstackPageHeader key={key} data={header?.node} />
      ))}

      {pageContents?.map((component: any, key: number) => {
        if (component.__typename === 'CategoryPagePageContentsLegacyCmsContent') {
          return <ContentstackLegacyCmsContent key={key} data={component.legacy_cms_content?.legacy_cms_contentConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsEscapeHatch') {
          return <ContentstackEscapeHatch key={key} data={component.escape_hatch?.escape_hatchConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsVerticalMediaBoxGroup') {
          return <ContentstackVerticalMediaBoxGroup key={key} data={component.vertical_media_box_group?.referenceConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsTopic') {
          return <ContentstackTopic key={key} data={component.topic?.topicConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsMediaCalloutGroup') {
          return <ContentstackMediaCalloutGroup key={key} data={component.media_callout_group?.media_callout_groupConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsBlogCardGroup') {
          return <ContentstackBlogCardGroup key={key} data={component.blog_card_group?.blog_card_groupConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsCardGroup') {
          return <ContentstackCardGroup key={key} data={component.card_group?.card_groupConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsSearchBySerialNumber') {
          return <ContentstackSearchBySerialNumber key={key} data={component.search_by_serial_number?.search_by_serial_numberConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsVideoCarousel') {
          return <ContentstackVideoCarousel key={key} data={component.video_carousel?.video_carouselConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsProductCardCarousel') {
          return <ContentstackProductCardCarousel key={key} data={component.product_card_carousel?.product_card_carouselConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsLargeShoppingBuckets') {
          return <ContentstackLargeShoppingBuckets key={key} data={component.large_shopping_buckets?.large_shopping_bucketsConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsRow') {
          return <ContentstackRow key={key} data={component.row?.rowConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsSmallShoppingBuckets') {
          return <ContentstackSmallShoppingBuckets key={key} data={component.small_shopping_buckets?.small_shopping_bucketsConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsCardWithBackgroundGroup') {
          return <ContentstackCardWithBackgroundGroup key={key} data={component.card_with_background_group?.card_with_background_groupConnection?.edges?.[0]?.node} />
        } else if (component.__typename === 'CategoryPagePageContentsStoneShapeIcons') {
          return <ContentstackStoneShapeIcons key={key} data={component.stone_shape_icons?.stone_shape_iconsConnection?.edges?.[0]?.node} />
        }

        return null
      })}
    </>
  )
}

CategoryPage.getInitialProps = getInitialProps

export {
  CategoryPage
}
